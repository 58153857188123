import MMarkdown from 'react-markdown'
import { Typography } from '@mui/material'

const Markdown: React.FC<React.ComponentProps<typeof MMarkdown>> = ({ children, ...props }) => (
  <MMarkdown
    components={{
      h1: (p: any) => <Typography variant="h1">{p.children}</Typography>,
      h2: (p: any) => <Typography variant="h2">{p.children}</Typography>,
      h3: (p: any) => <Typography variant="h3">{p.children}</Typography>,
      h4: (p: any) => <Typography variant="h4">{p.children}</Typography>,
      p: (p: any) => <Typography variant="body1">{p.children}</Typography>,
      li: (p: any) => <li style={{ fontWeight: 400 }}>{p.children}</li>
    }}
    {...props}
  >
    {children}
  </MMarkdown>
)

export default Markdown
