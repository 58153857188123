import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import moment from 'moment'
import { PromptSaveDialog } from '../components/molecules'
import { getDisabledRoutes, getExpiry, getUser } from '../utils/helper'
import ErrorView from '../views/ErrorView'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import routes from './routes'

const Router = () => {
  const [confirmOpen, setConfirmOpen] = useState(true)
  const getUserConfirmation = (message: string, callback: (ok: boolean) => void) =>
    moment.unix(parseFloat(String(getExpiry())) ?? 0).diff(moment()) > 10000
      ? PromptSaveDialog(message, callback, confirmOpen, setConfirmOpen)
      : callback(true)

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <Route
          render={({ location }) => {
            const { role } = JSON.parse(getUser() ?? '{}') || {}

            return (
              <Switch location={location ?? undefined}>
                {routes
                  .filter(route => !getDisabledRoutes(role).includes(route.path))
                  .map(({ path, component, exact, isPublic, restricted }) =>
                    isPublic ? (
                      <PublicRoute
                        key={path}
                        restricted={restricted}
                        component={component}
                        path={path}
                        exact={exact}
                      />
                    ) : (
                      <PrivateRoute key={path} component={component} path={path} exact={exact} />
                    )
                  )}
                <Route component={() => <Redirect to="/cases" />} />
              </Switch>
            )
          }}
        />
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default Router
