import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp'
import { IconButton, Tooltip } from '@mui/material'
import { resolveNestedObject, capitalizeFirstLetter } from '../../../utils/helper'
import MessageBox from '../MessageBox'
import { TVariant } from '../MessageBox/MessageBox'

interface CopyAddressButtonProps {
  prefix?: string
}
interface IMessage {
  message: string
  open: boolean
  variant?: TVariant
}

const DEFAULT_MESSAGE: IMessage = {
  message: '',
  open: false
}
const CopyAddressButton: React.FC<CopyAddressButtonProps> = ({ prefix = '' }) => {
  const { t } = useTranslation('common')
  const [copied, setCopied] = useState<IMessage>(DEFAULT_MESSAGE)
  const attributes = [
    'salutation',
    'company',
    'firstname',
    'lastname',
    'street',
    'houseNumber',
    'postalCode',
    'place',
    'country'
  ]
  const methods = useFormContext()
  const { getValues } = methods || {}

  const generateAddress = () => {
    const values = getValues()
    const obj: { [key: string]: string } = {}

    attributes.forEach(attribute => {
      const key = prefix.includes('.') ? attribute : capitalizeFirstLetter(attribute)

      obj[attribute] = resolveNestedObject(`${prefix}${key}`, values)
    })

    const person = [obj?.salutation, obj?.firstname, obj?.lastname].filter(l => !!l).join(' ')
    const firstLines = [obj?.company, person].filter(l => !!l).join('\n')
    const country = obj.country ? `${obj.country}\n` : undefined
    return [
      firstLines,
      `${obj.street ?? ''} ${obj.houseNumber ?? ''}`,
      `${obj.postalCode ?? ''} ${obj.place ?? ''}`,
      country
    ]
      .filter(l => !!l)
      .join('\n')
  }

  const copyToClipboard = async () => {
    const address = generateAddress()
    try {
      await navigator.clipboard.writeText(address)
      setCopied({ message: t('copyAddress.success'), open: true, variant: 'success' })
      setTimeout(() => setCopied(DEFAULT_MESSAGE), 2000)
    } catch (error) {
      setCopied({ message: t('copyAddress.error'), open: true, variant: 'error' })
      setTimeout(() => setCopied(DEFAULT_MESSAGE), 2000)
      console.error(t('copyAddress.error'), error)
    }
  }

  return (
    <>
      <Tooltip title={t('copyAddress.copy')} placement="top">
        <IconButton size="small" onClick={copyToClipboard}>
          {copied.open ? <CheckSharpIcon /> : <ContentCopySharpIcon />}
        </IconButton>
      </Tooltip>
      <MessageBox {...copied} />
    </>
  )
}

export default CopyAddressButton
