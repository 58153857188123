import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLogin } from '../utils/helper'
import { Route as RouteType } from './routes'

const PublicRoute: React.FC<RouteType> = ({
  component: Component,
  restricted = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLogin() && restricted ? (
        <Redirect to="/dashboard" />
      ) : (
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      )
    }
  />
)

export default PublicRoute
