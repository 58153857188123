import { lazyWithRetry } from '../utils/lazyWithRetry'

export interface Route {
  path: string
  exact: boolean
  isPublic?: boolean
  restricted?: boolean
  component: React.ElementType
}
const mediatorInfoEnabled = process.env.REACT_APP_MEDIATOR_INFO_ENABLED === 'true'
const profileEnabled = process.env.REACT_APP_PROFILE_ENABLED === 'true'
const routes: Route[] = [
  {
    path: '/',
    exact: true,
    isPublic: true,
    restricted: true,
    component: lazyWithRetry(() => import('../views/Login'))
  },
  {
    path: '/login',
    exact: true,
    isPublic: true,
    restricted: true,
    component: lazyWithRetry(() => import('../views/Login'))
  },
  {
    path: '/dashboard',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Dashboard'))
  },
  {
    path: '/evaluation-rb',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/EvaluationRB'))
  },
  {
    path: '/tasks',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Tasks'))
  },
  {
    path: '/cases',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Cases'))
  },
  {
    path: '/cases/new',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/CaseCreate'))
  },
  {
    path: '/cases/new-legal-advice',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/CreateLegalAdvice'))
  },
  {
    path: '/cases/:id',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Case'))
  },
  {
    path: '/cases/:id/processing/:processingId',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Case'))
  },
  {
    path: '/cases-creator/',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Cases/CreatorCasesEditable'))
  },
  {
    path: '/pools/:slug',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Pool'))
  },
  {
    path: '/cases-creator/:id',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/CaseCreator'))
  },
  {
    path: '/payouts',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/Payouts'))
  },
  ...(mediatorInfoEnabled
    ? [
        {
          path: '/mediator-info',
          exact: true,
          isPublic: false,
          component: lazyWithRetry(() => import('../views/MediatorInfo'))
        }
      ]
    : []),
  {
    path: '/mediator-info/blog-post/:id',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/MediatorInfo/EditView'))
  },
  {
    path: '/mediator-info/edit-blog-post/:id',
    exact: true,
    isPublic: false,
    component: lazyWithRetry(() => import('../views/MediatorInfo/EditView'))
  },
  ...(profileEnabled
    ? [
        {
          path: '/profile',
          exact: true,
          isPublic: false,
          component: lazyWithRetry(() => import('../views/UserProfile'))
        }
      ]
    : [])
]

export default routes
