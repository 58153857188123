import { FC } from 'react'
import parse from 'autosuggest-highlight/parse'
import AddressItem from './AddressItem'

interface AddressItemsProps {
  props: React.HTMLAttributes<HTMLLIElement>
  option: google.maps.places.AutocompletePrediction
}

const AddressItems: FC<AddressItemsProps> = ({ props, option }) => {
  const optionProps = props
  const matches = option.structured_formatting.main_text_matched_substrings || []

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map(match => [match.offset, match.offset + match.length])
  )
  return <AddressItem option={option} parts={parts} optionProps={optionProps} />
}

export default AddressItems

export type { AddressItemsProps }
