import { useRef, FC } from 'react'

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

interface LoaderProps {
  children: React.ReactNode
}

const Loader: FC<LoaderProps> = ({ children }) => {
  const loaded = useRef(false)
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }
  return loaded.current ? <>{children}</> : <div>Loading...</div>
}

export default Loader
export type { LoaderProps }
