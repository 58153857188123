import React, { useState, useRef, useEffect } from 'react'
import { Tooltip, Box } from '@mui/material'
import colors from '../../../utils/colors'
import { OverflowTipProps } from './types'

const OverflowTip: React.FC<OverflowTipProps> = ({
  children,
  color = 'primaryColor',
  onClick,
  sx,
  title,
  position
}) => {
  const ellipsis = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }

  const [overflowed, setOverflowed] = useState(false)
  const textElement = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (textElement?.current) {
      setOverflowed(textElement.current.scrollWidth - 5 > textElement.current.clientWidth)
    }
  }, [])

  return (
    <Tooltip
      title={title ?? children}
      disableHoverListener={!overflowed}
      onClick={onClick}
      placement={position}
    >
      <Box ref={textElement} sx={{ ...ellipsis, color: colors[color], ...sx }}>
        {children}
      </Box>
    </Tooltip>
  )
}

export default OverflowTip
