import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useMutation } from '@apollo/client'
import { Box, CircularProgress, Grid } from '@mui/material'
import * as Sentry from '@sentry/browser'
import consumer from '../../../cable'
import { KEYWORDIFY_MUTATION } from '../../../graphql/mutations'
import { LOOKUP_TABLE_QUERY } from '../../../graphql/queries'
import { getClient, getToken } from '../../../utils/helper'
import { useSnackbar, useUser } from '../../../utils/hooks'
import { AutoComplete, Button, Input } from '../../atoms'

interface TextfieldWithAiProps {
  disabled?: boolean
  options?: any
  onChange?: any
  onBlur?: any
  name?: string
  value?: string
  label?: string
  error?: any
  helperText?: string
}

const TextfieldWithAi = React.forwardRef<React.FC, TextfieldWithAiProps>(
  ({ disabled, options, onChange, onBlur, name, value, label, helperText, error }, ref) => {
    const { t } = useTranslation(['ai', 'common'])
    const location = useLocation()
    const {
      setValue,
      control,
      formState: { errors }
    } = useFormContext()
    useTranslation('common')
    const queryParams = new URLSearchParams(location?.search)
    const actionId = queryParams.get('action')
    const { keyword: keywordError } = errors || {}
    const { message: keywordErrorMessage } = keywordError || {}

    const [keywordify] = useMutation(KEYWORDIFY_MUTATION)
    const { setSnackbar } = useSnackbar()
    const [hermineRequestId, setHermineRequestId] = React.useState<string | undefined>(undefined)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { user } = useUser()
    useEffect(() => {
      if (!!hermineRequestId) {
        const token = getToken()
        const client = getClient()
        const params = {
          channel: 'HermineRequestChannel',
          user_id: `${user?.id}`,
          hermine_request_id: hermineRequestId,
          token,
          client
        }
        console.debug('[*] creating hermine request subscription')
        consumer.subscriptions.create(params, {
          connected: () => {
            console.debug('[+] ActionCable - HermineRequestChannel - CONNECTED')
          },
          disconnected: () => {
            console.debug('[-] ActionCable - HermineRequestChannel - DISCONNECTED')
          },
          received: (data: any) => {
            console.debug('[+] ActionCable - HermineRequestChannel - received data: ', data)
            setLoading(false)
            if (data?.response) {
              setValue('keyword', data.response, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true
              })
            } else if (data?.error_log) {
              Sentry.captureMessage('HermineRequest failed for dispute value calculation.', {
                extra: data,
                level: 'warning'
              })
              setSnackbar({
                open: true,
                variant: 'error',
                text: t('common:errorLong')
              })
            }
          }
        })
      }
      return () => {
        console.debug('[-] Unmounting HermineRequestChannel')
        const token = getToken()
        const client = getClient()
        const params = {
          channel: 'HermineRequestChannel',
          user_id: `${user?.id}`,
          hermine_request_id: hermineRequestId,
          token,
          client
        }
        const sub = consumer.subscriptions.subscriptions?.find(
          (s: any) => s.identifier === JSON.stringify(params)
        )

        sub?.unsubscribe()
      }
    }, [hermineRequestId, setSnackbar, setValue, t, user?.id])

    const onButtonClick = () => {
      setLoading(true)
      keywordify({
        variables: {
          attributes: {
            text: value,
            resourceId: actionId,
            resourceType: 'Actions::Action'
          }
        },
        onCompleted: (data: any) => {
          if (data?.keywordify?.id) setHermineRequestId(data?.keywordify?.id)
          else {
            Sentry.captureException(new Error('Hermine not available'), { extra: data })
            setSnackbar({
              open: true,
              variant: 'error',
              text: t('common:errorLong')
            })
          }
        },
        onError: (errorData: any) => {
          if (errorData.message === 'global.error.hermineNotAvailable') {
            setSnackbar({
              open: true,
              variant: 'error',
              text: t('ai:hermineNotAvailable')
            })
          } else {
            Sentry.captureException(new Error('Hermine not available'), { extra: errorData })
            setSnackbar({
              open: true,
              variant: 'error',
              text: t('common:errorLong')
            })
          }
        }
      })
    }

    return (
      <Grid container item spacing={2}>
        <Grid item xs={8}>
          <Input
            {...options}
            id={name}
            noPlaceholder
            ref={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            multiline
            // @ts-ignore
            rows={options?.autosize ? undefined : options?.rows || 5}
            fullWidth
            // @ts-ignore
            type="textfield"
            label={label}
            disabled={disabled}
            helperText={helperText}
            error={error}
          />
        </Grid>
        <Grid container item xs={4}>
          <Grid item xs={12}>
            <Button disabled={disabled || !value} fullWidth onClick={onButtonClick}>
              {t('ai:suggestKeyword')}
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="keyword"
              control={control}
              render={({ field }) => (
                <AutoComplete
                  {...field}
                  label={t('ai:keyword')}
                  disabled={disabled}
                  query={LOOKUP_TABLE_QUERY}
                  error={keywordError}
                  helperText={keywordErrorMessage ? t(keywordErrorMessage as string) : undefined}
                  minCharsForSearch={0}
                  queryOptions={{
                    variables: {
                      filter: { lookupType: 'keyword' },
                      sortBy: { attribute: 'value', direction: 'asc' }
                    },
                    valueField: 'value',
                    labelField: 'value'
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
)

export default TextfieldWithAi
