import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'
import { debounce } from '@mui/material/utils'
import { AddressItems, Loader } from './components'

interface AddressAutocompleteProps {
  disabled?: boolean
  label?: string
  name?: string
  onChange?: (value: any) => void
  onSelected?: (value: any) => void
  options?: any
  value?: string
  error?: boolean
  helperText?: string
}

const AddressAutocomplete = React.forwardRef(
  (
    {
      disabled,
      label,
      name,
      onChange,
      onSelected,
      options,
      value,
      error,
      helperText
    }: AddressAutocompleteProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation('common')
    const methods = useFormContext()
    const { setValue } = methods || {}
    const [inputOptions, setInputOptions] = React.useState<any[]>([])

    const [inputValue, setInputValue] = React.useState(value || '')
    React.useEffect(() => {
      if (value !== inputValue) setInputValue(value || '')
    }, [value, setInputValue, inputValue])

    const autoCompletService = React.useRef<google.maps.places.AutocompleteService | null>(null)

    const fetch = React.useRef(
      debounce(
        (
          request,
          callback: (
            a: google.maps.places.AutocompletePrediction[] | null,
            b: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          autoCompletService.current?.getPlacePredictions(request, callback)
        },
        400
      )
    ).current

    React.useEffect(() => {
      let active = true
      if (!autoCompletService.current && window.google) {
        autoCompletService.current = new window.google.maps.places.AutocompleteService()
      }
      if (!autoCompletService.current) {
        return undefined
      }
      if (inputValue === '') {
        setInputOptions([])
        return undefined
      }

      fetch(
        {
          input: inputValue,
          types: ['postal_code']
        },
        (results: google.maps.places.AutocompletePrediction[] | null) => {
          if (active) {
            let newOptions: any[] = []

            if (results) {
              newOptions = [...newOptions, ...results]
            }

            setInputOptions(newOptions)
          }
        }
      )

      return () => {
        active = false
      }
    }, [inputValue, fetch])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
      onChange?.(event.target.value)
    }

    const handleSelectionChange = (event: React.ChangeEvent<{}>, val: any | null) => {
      if (val) {
        const postalCode = val?.terms?.[0]?.value
        const place = val?.terms?.[1]?.value

        onChange?.(val)
        setValue(name || '', postalCode)
        setValue(options?.dependency || '', place)
        onSelected?.(val)
      }
    }

    return (
      <Loader>
        <Autocomplete
          disabled={disabled}
          filterOptions={x => x}
          size="small"
          freeSolo
          options={inputOptions}
          getOptionLabel={option => (typeof option === 'string' ? option : option.terms[0].value)}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) =>
            handleInputChange({
              target: { value: newInputValue }
            } as React.ChangeEvent<HTMLInputElement>)
          }
          noOptionsText={t('noOptions')}
          onChange={handleSelectionChange}
          renderOption={(props, option) => <AddressItems props={props} option={option} />}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label={label}
              variant="outlined"
              inputRef={ref}
            />
          )}
        />
      </Loader>
    )
  }
)

export default AddressAutocomplete
