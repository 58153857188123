import React from 'react'
import { Box, Typography } from '@mui/material'
import { uniqueId } from 'lodash'
import { FileType } from '../../../utils/fixtures'
import { FileListItem } from '../../atoms'

export interface FilesListProps {
  files?: FileType[]
  label?: string
  background?: string
}

const FilesList: React.FunctionComponent<FilesListProps> = ({
  files = [],
  label = 'Dateien',
  background = 'white'
}) =>
  files?.length ? (
    <Box
      sx={{
        px: '8px',
        py: '16px',
        width: '100%',
        border: theme => `1px solid ${theme?.palette?.grey?.[300]}`,
        borderRadius: '4px',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          px: '5px',
          fontSize: '1rem',
          transformOrigin: 'top left',
          transform: 'translate(9px, -9px) scale(0.75)',
          // @ts-ignore
          background: theme => theme?.palette?.[background || 'white']
        }}
      >
        <Typography>{label}</Typography>
      </Box>
      {files?.map(file => <FileListItem key={file.path || file.id || uniqueId()} file={file} />)}
    </Box>
  ) : null

export default FilesList
