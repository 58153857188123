import React, { Dispatch, SetStateAction, useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import consumer from '../cable'
import { ACTIONS_QUERY, USER_STATS_QUERY } from '../graphql/queries'
import { Action, UserStat } from './fixtures'
import {
  getUser,
  setUser as setStorageUser,
  removeUser as removeStorageUser,
  getToken,
  getClient
} from './helper'

type ContextType = {
  processingId?: string
  setProcessingId: Dispatch<SetStateAction<string | undefined>>
  actions?: Action[]
  userStats?: UserStat
  loadingActions: boolean
  loadingUserStats: boolean
  errorActions?: ApolloError
  errorUserStats?: ApolloError
  isEditorModalOpen: boolean
  setIsEditorModalOpen: Dispatch<SetStateAction<boolean>>
  refetchUserStats: (variables?: undefined) => Promise<ApolloQueryResult<any>>
  refetchActions: (
    variables?:
      | Partial<{
          processingId?: string
          sortBy?: { attribute: string; direction: string }
          filter?: { [key: string]: any }
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>

  user: any
  setUser: (userData: any) => void
  isEditor: boolean
  isAdmin: boolean
  isCreator: boolean
  isExternal: boolean
  isLegalAdvisor: boolean
  isEmployeeHohner: boolean
  removeUser: () => void
}

interface UserContextProviderProps {
  children: React.ReactNode
}

const initialValues = {
  name: '',
  firstname: '',
  lastname: '',
  role: '',
  userStats: undefined,
  actions: [],
  loadingActions: true,
  loadingUserStats: true,
  errorActions: undefined,
  errorUserStats: undefined,
  refetchUserStats: () => new Promise(() => {}),
  refetchActions: () => new Promise(() => {}),
  processingId: undefined,
  isEditorModalOpen: false
}

const getStoreUser = () => {
  const user = { ...JSON?.parse(getUser() || '{}') }
  user.name = [user.firstname, user.lastname].join(' ')
  return user
}

const context = {
  user: { ...initialValues, ...getStoreUser() },
  setUser: () => {},
  isEditor: false,
  isAdmin: false,
  isCreator: false,
  isExternal: false,
  isLegalAdvisor: false,
  isEmployeeHohner: false,
  removeUser: () => {},
  processingId: initialValues.processingId,
  setProcessingId: () => {},
  actions: initialValues.actions,
  userStats: initialValues.userStats,
  loadingActions: initialValues.loadingActions,
  loadingUserStats: initialValues.loadingUserStats,
  errorActions: initialValues.errorActions,
  errorUserStats: initialValues.errorUserStats,
  refetchUserStats: () => new Promise<any>(() => {}),
  refetchActions: () => new Promise<any>(() => {}),
  isEditorModalOpen: initialValues.isEditorModalOpen,
  setIsEditorModalOpen: () => {}
}

const UserContext = React.createContext<ContextType>(context)

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [user, setLocalUser] = useState(() => context.user)

  const isLegalAdvisor = useMemo(() => ['legal_advisor'].includes(user?.role), [user?.role])
  const isEditor = useMemo(() => ['user', 'legal_advisor'].includes(user?.role), [user?.role])
  const isAdmin = useMemo(() => ['admin'].includes(user?.role), [user?.role])
  const isCreator = useMemo(() => ['creator'].includes(user?.role), [user?.role])
  const isEmployeeHohner = user?.employee_hohner === true
  const isExternal = useMemo(
    () => ![...new Set([isAdmin, isEditor, isCreator, isLegalAdvisor])].includes(true),
    [isAdmin, isEditor, isCreator, isLegalAdvisor]
  )

  const [processingId, setProcessingId] = useState<string | undefined>(initialValues.processingId)
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
  const {
    data: userStatsData,
    loading: loadingUserStats,
    error: errorUserStats,
    refetch: refetchUserStats
  } = useQuery(USER_STATS_QUERY, {
    fetchPolicy: 'network-only',
    skip: !user?.id,
    ssr: false,
    // pollInterval,
    variables: {
      isLegalAdvisor: isAdmin || isLegalAdvisor,
      isAdmin
    }
  })

  useEffect(() => {
    const token = getToken()
    const client = getClient()
    const params = {
      channel: 'UserChannel',
      user_id: `${user?.id}`,
      token,
      client
    }
    if (
      !consumer.subscriptions.subscriptions.find((sub: any) => sub.identifier === params) &&
      user?.id
    ) {
      console.debug('[*] creating user subscription')
      consumer.subscriptions.create(params, {
        connected: () => {
          console.debug('[+] ActionCable - CONNECTED')
        },
        disconnected: () => {
          console.debug('[-] ActionCable - DISCONNECTED')
        },
        received: (data: any) => {
          console.debug('[+] ActionCable - received data: ', data)
          switch (data.action) {
            case 'call-accepted':
              console.debug('user', user)
              console.debug('data', data)
              console.debug('data.m2_case_id', data.m2_case_id)
              console.debug('data.processing_id', data.processing_id)
              if (isEditor) {
                window.location =
                  `/cases/${data.m2_case_id}/processing/${data.processing_id}/?tab=processing&action=${data.action_id}` as any
              } else {
                window.location =
                  `/cases/${data.m2_case_id}/processing/${data.processing_id}/?subTab=processing&tab=processing-${data.processing_id}&action=${data.action_id}` as any
              }
              break
            case 'user-stats':
              refetchUserStats()
              break
            default:
              break
          }
        }
      })
      console.debug('subscriptions', consumer.subscriptions)
    }

    return () => {
      const sub = consumer.subscriptions.subscriptions?.find(
        (s: any) => s.identifier === JSON.stringify(params)
      )

      console.debug('[-] ActionCable - Unmounting UserContext')
      sub?.unsubscribe()
    }
  }, [user?.id, refetchUserStats, user, isEditor])

  useEffect(() => {
    const token = getToken()
    const client = getClient()
    const params = {
      channel: 'AdminChannel',
      admin_id: `${user?.id}`,
      token,
      client
    }
    if (
      isAdmin &&
      user?.id &&
      !consumer.subscriptions.subscriptions.find(
        (sub: any) => sub.identifier === JSON.stringify(params)
      )
    ) {
      console.debug('[*] creating admin subscription')
      consumer.subscriptions.create(params, {
        connected: () => {
          console.debug('[+] ActionCable - AdminChannel - CONNECTED')
        },
        disconnected: () => {
          console.debug('[-] ActionCable - AdminChannel - DISCONNECTED')
        },
        received: (data: any) => {
          console.debug('[+] ActionCable - AdminChannel - received data: ', data)
          if (data?.action === 'user-stats') {
            refetchUserStats()
          }
        }
      })
      console.debug('subscriptions', consumer.subscriptions)
    }
    return () => {
      if (isAdmin) {
        const sub = consumer.subscriptions.subscriptions?.find(
          (s: any) => s.identifier === JSON.stringify(params)
        )

        console.debug('[-] Unmounting AdminChannel - UserContext')
        sub?.unsubscribe()
      }
    }
  }, [user?.id, refetchUserStats, isAdmin])

  const {
    data: actionsData,
    loading: loadingActions,
    error: errorActions,
    refetch: refetchActions
  } = useQuery(ACTIONS_QUERY, {
    skip: !processingId || processingId.length <= 0 || !user?.id,
    variables: {
      processingId,
      sortBy: {
        attribute: 'date',
        direction: 'desc'
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (processingId && !isEditorModalOpen) {
      refetchActions()
    }
  }, [processingId, refetchActions, isEditorModalOpen])

  const [userStats, setUserStats] = useState(undefined)
  useEffect(() => {
    if (userStatsData?.userStats && !isEditorModalOpen) {
      setUserStats(userStatsData?.userStats)
    }
  }, [setUserStats, userStatsData, isEditorModalOpen])

  const [actions, setActions] = useState([])
  useEffect(() => {
    if (actionsData?.actions?.collection && !isEditorModalOpen) {
      setActions(actionsData?.actions?.collection)
    }
  }, [setActions, actionsData, isEditorModalOpen])

  const setUser = useCallback((userData: any) => {
    if (userData) {
      Sentry.setUser({ email: userData?.email })
      setStorageUser(userData)
      setLocalUser((usr: any) => ({
        ...usr,
        ...userData,
        role: userData?.role,
        name: [userData.firstname, userData.lastname].join(' ')
      }))
    }
  }, [])

  const removeUser = () => {
    Sentry.setUser(null)
    removeStorageUser()
    setLocalUser(initialValues)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        removeUser,
        isEditor,
        isAdmin,
        isCreator,
        isExternal,
        isLegalAdvisor,
        isEmployeeHohner,
        processingId,
        setProcessingId,
        loadingActions,
        loadingUserStats,
        errorActions,
        errorUserStats,
        actions,
        userStats,
        refetchActions,
        refetchUserStats,
        isEditorModalOpen,
        setIsEditorModalOpen
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserContext
