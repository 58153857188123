import { FC } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Grid, Typography } from '@mui/material'

interface AddressItemProps {
  option: google.maps.places.AutocompletePrediction
  parts: {
    text: string
    highlight: boolean
  }[]
  optionProps: React.HTMLAttributes<HTMLLIElement>
}

const AddressItem: FC<AddressItemProps> = ({ option, parts, optionProps }) => (
  <li key={option.description} {...optionProps}>
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item sx={{ display: 'flex', width: 44 }}>
        <LocationOnIcon sx={{ color: 'text.secondary' }} />
      </Grid>
      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
        {parts.map(part => (
          <Box
            key={part.text}
            component="span"
            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
          >
            {part.text}
          </Box>
        ))}
        <Typography variant="body2" color="text.secondary">
          {option.structured_formatting.secondary_text}
        </Typography>
      </Grid>
    </Grid>
  </li>
)

export default AddressItem
export type { AddressItemProps }
