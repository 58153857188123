import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Layout from '../layouts'
import { isLogin } from '../utils/helper'
import TimeOut from '../utils/TimeOut'
import { Route as RouteType } from './routes'

const PrivateRoute: React.FC<RouteType> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogin() ? (
        <Layout>
          <TimeOut />
          <Suspense fallback={null}>
            <Component {...props} />
          </Suspense>
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)

export default PrivateRoute
