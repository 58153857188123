import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { TextField, OutlinedTextFieldProps, InputAdornment, Tooltip } from '@mui/material'
import MailTo from '../MailTo'
import { PhoneNumberInput, CustomNumber } from './components'

export interface InputProps extends OutlinedTextFieldProps {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  multiline?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  rows?: number
  showPassword?: boolean
  small?: boolean
  type?: 'default' | 'money' | 'password' | 'textfield' | 'phone' | 'email'
  value?: string
  defaultValue?: string
  name?: string
  error?: any
  autoFocus?: boolean
  helperText?: string
  helperTextColor?: string | undefined
  noPlaceholder?: boolean
  thickBorder?: boolean
  subject?: string
  body?: string
  mask?: string
  maskChar?: string[0]
  alwaysShowMask?: boolean
  permanents?: number[]
}
type InputPropsType = Omit<InputProps, 'variant'>

const Input = React.forwardRef(
  (
    {
      thickBorder,
      disabled,
      fullWidth,
      label,
      multiline,
      onChange,
      onBlur,
      rows,
      showPassword,
      small,
      type,
      value,
      defaultValue,
      name,
      error,
      autoFocus,
      helperText,
      helperTextColor,
      noPlaceholder,
      mask,
      maskChar,
      alwaysShowMask,
      permanents,
      ...props
    }: InputPropsType,
    ref
  ): JSX.Element => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
      setVisible(!!showPassword)
    }, [showPassword])

    interface IDefaultFieldProps {
      variant: 'outlined'
      disabled?: boolean
      autoFocus?: boolean
      label?: string
      name?: string
      onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      size?: 'small' | 'medium'
      value?: string
      defaultValue?: string
      fullWidth?: boolean
      multiline?: boolean
      rows?: number
      error?: any
      helperText?: string
      thickBorder?: boolean
      subject?: string
      body?: string
    }

    const defaultFieldProps = {
      ...props,
      variant: 'outlined',
      disabled,
      autoFocus,
      label,
      name,
      onChange,
      onBlur,
      size: small ? 'small' : 'medium',
      value,
      defaultValue,
      fullWidth,
      multiline,
      rows,
      error: !!error,
      helperText,
      ...(thickBorder
        ? {
            sx: (theme: any) => ({
              '& .MuiOutlinedInput-notchedOutline': {
                border: `5px solid ${theme?.palette?.petrol} !important`
              },
              '& .Mui-focused > .MuiOutlinedInput-notchedOutline ': {
                border: '2px solid #3b82f6 !important'
              }
            })
          }
        : {})
    } as IDefaultFieldProps

    interface IInputProps {
      endAdornment?: string
      inputComponent: React.FC
    }

    interface IMoneyFieldProps {
      InputProps: IInputProps
    }

    interface IEmailFieldProps {
      InputProps: {
        endAdornment: JSX.Element
      }
    }

    const moneyFieldProps = {
      InputProps: {
        endAdornment: '€',
        inputComponent: CustomNumber
      }
    } as IMoneyFieldProps

    const phoneFieldProps = {
      InputProps: {
        inputComponent: PhoneNumberInput
      },
      InputLabelProps: { shrink: true }
    }

    const emailFieldProps = {
      InputProps: {
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              marginRight: '-10px'
            }}
          >
            <MailTo
              email={value}
              subject={defaultFieldProps.subject}
              body={defaultFieldProps.body}
            />
          </InputAdornment>
        )
      }
    } as IEmailFieldProps

    const passwordFieldProps = { type: visible ? 'test' : 'password' }

    if (!!mask)
      return (
        <InputMask
          mask={mask}
          maskChar={maskChar}
          value={value}
          onChange={onChange}
          alwaysShowMask={alwaysShowMask}
          permanents={permanents}
          formatChars={{
            '9': '[0-9]',
            z: '[A-Za-z]',
            Z: '[A-Z]',
            '*': '[A-Za-z0-9]'
          }}
        >
          {/* @ts-ignore */}
          {(inputProps: any) => (
            <TextField
              {...inputProps}
              {...defaultFieldProps}
              {...props}
              inputRef={ref}
              onChange={undefined}
              onBlur={undefined}
              value={undefined}
              disabled={undefined}
            />
          )}
        </InputMask>
      )

    if (type === 'money') {
      return <TextField inputRef={ref} {...defaultFieldProps} {...moneyFieldProps} />
    }

    if (type === 'password') {
      return <TextField inputRef={ref} {...defaultFieldProps} {...passwordFieldProps} />
    }

    if (type === 'email') {
      return <TextField inputRef={ref} {...defaultFieldProps} {...emailFieldProps} />
    }

    if (type === 'phone') {
      return (
        <Tooltip
          title={value ? formatPhoneNumberIntl(value) : ''}
          placement="top"
          slotProps={{
            tooltip: {
              style: {
                fontSize: '72px',
                letterSpacing: '0.1em',
                maxWidth: 'none',
                width: 'fit-content'
              }
            }
          }}
          disableInteractive
          disableTouchListener
        >
          <TextField
            inputRef={ref}
            {...defaultFieldProps}
            {...phoneFieldProps}
            sx={{
              '& .PhoneInput': { display: 'flex' },
              '& .PhoneInputInput': {
                border: 'none',
                fontFamily: 'fontFamily',
                font: 'inherit',
                color: 'currentColor'
              },
              '& .PhoneInputInput::focus-visible': {
                backgroundColor: 'lime'
              },
              '& .PhoneInput--focus input': {
                outline: 'none !important'
              }
            }}
          />
        </Tooltip>
      )
    }

    return (
      <TextField
        inputRef={ref}
        {...defaultFieldProps}
        InputLabelProps={noPlaceholder ? { shrink: true } : undefined}
        {...(!error && helperTextColor
          ? {
              FormHelperTextProps: {
                sx: {
                  color: helperTextColor
                }
              }
            }
          : {})}
      />
    )
  }
)
Input.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  error: false,
  disabled: false,
  fullWidth: false,
  label: '',
  multiline: false,
  showPassword: false,
  small: true,
  autoFocus: false,
  type: 'default',
  helperText: undefined,
  noPlaceholder: false
}

export default Input
