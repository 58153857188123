import React from 'react'
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Select } from '../../atoms'

interface CaseStateSelectProps<TFieldValues extends FieldValues = FieldValues> {
  control: Control<TFieldValues>
  hidden?: boolean
  disabled?: boolean
  name?: FieldPath<TFieldValues>
}

const CaseStateSelect = <TFieldValues extends FieldValues = FieldValues>({
  control,
  hidden = false,
  disabled = false,
  name = 'caseState' as FieldPath<TFieldValues>
}: CaseStateSelectProps<TFieldValues>) => {
  const { t } = useTranslation('caseProperty')
  const items = [
    { value: 'in_capture', label: t('inCapture') },
    { value: 'open', label: t('open') },
    { value: 'coverage_request', label: t('coverageRequest') },
    { value: 'in_progress', label: t('inProgress') },
    { value: 'jump_off', label: t('jumpOff') },
    { value: 'completed', label: t('completed') },
    { value: 'checked', label: t('checked') },
    { value: 'settled', label: t('settled') }
  ]
  if (hidden) return null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          variant="standard"
          value={value}
          onChange={onChange}
          ref={ref}
          items={items}
          label={t('caseState')}
          disabled={disabled}
        />
      )}
    />
  )
}

export default CaseStateSelect
