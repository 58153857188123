import React, { useEffect, useState } from 'react'
import { Droppable, DragDropContext } from 'react-beautiful-dnd'
import { moveElementInArray } from '../../../utils/helper'
import ListItem from './ListItem'

interface StrictModeDroppableProps extends React.ComponentProps<typeof Droppable> {}

const StrictModeDroppable: React.FC<StrictModeDroppableProps> = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])
  if (!enabled) {
    return null
  }
  return <Droppable {...props}>{children}</Droppable>
}

interface DroppableItem {
  key: string
  displayName: string
  checked: boolean
  disabled: boolean
}

interface SwitchListGroupProps {
  data: DroppableItem[]
  onChanged: (data: any) => void
}

const SwitchListGroup: React.FC<SwitchListGroupProps> = React.memo(({ data, onChanged }) => {
  const [list, setList] = useState(data)
  useEffect(() => {
    setList(data)
  }, [setList, data])

  const handleDragEnd = (result: any) => {
    const { destination, source } = result
    const listCopy = moveElementInArray(list, source?.index, destination?.index)
    setList(listCopy)
  }

  const handleChange = (key: string) => {
    const found = list.find(item => item.key === key)

    if (!found) {
      return
    }

    const listCopy = list.map(item => {
      if (item.key === key) {
        return { ...found, checked: !found.checked }
      }
      return item
    })

    setList(listCopy)
    onChanged(listCopy)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StrictModeDroppable droppableId="1">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list?.map((item, index) => {
              const { key, checked, disabled, displayName } = item
              return (
                <ListItem
                  onChange={() => handleChange(key)}
                  label={displayName}
                  defaultChecked={checked}
                  disabled={disabled}
                  id={key}
                  key={key}
                  index={index}
                  last={index === list.length - 1}
                />
              )
            })}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  )
})

export default SwitchListGroup
