import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Info } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import * as Sentry from '@sentry/react'
import consumer from '../../../cable'
import { CREATE_HERMINE_REQUEST_MUTATION } from '../../../graphql/mutations'
import { getClient, getToken } from '../../../utils/helper'
import { useCase, useSnackbar, useUser } from '../../../utils/hooks'
import { Button, Input } from '../../atoms'
import Markdown from '../Markdown'

interface DisputeValueModalProps {
  setValue?: (name: string, value: any, options?: any) => void
  name: string
  open?: boolean
  setOpen?: (open: boolean) => void
  watch: (name: string) => any
}

const DisputeValueModal: React.FC<DisputeValueModalProps> = ({
  open: propOpen,
  setOpen: setPropOpen,
  setValue,
  name,
  watch
}) => {
  const disputeValueCalculationDescription = watch?.('disputeValueCalculationDescription')
  const context = watch(name)

  const { t } = useTranslation('ai')
  const { setSnackbar } = useSnackbar()
  const [open, setOpen] = useState<boolean>(propOpen || false)
  const [hermineRequestFailed, setHermineRequestFailed] = useState<boolean>(false)

  useEffect(() => {
    setOpen(!!propOpen)
  }, [propOpen])

  const toggleOpen = useCallback(
    (newOpen?: boolean) => {
      setOpen(newOpen === undefined ? !open : newOpen)
      setPropOpen?.(newOpen === undefined ? !open : newOpen)
    },
    [open, setPropOpen]
  )
  const { user } = useUser()

  const [hermineRequestId, setHermineRequestId] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (!!hermineRequestId) {
      const token = getToken()
      const client = getClient()
      const params = {
        channel: 'HermineRequestChannel',
        user_id: `${user?.id}`,
        hermine_request_id: hermineRequestId,
        token,
        client
      }
      console.debug('[*] creating hermine request subscription')
      consumer.subscriptions.create(params, {
        connected: () => {
          console.debug('[+] ActionCable - HermineRequestChannel - CONNECTED')
        },
        disconnected: () => {
          console.debug('[-] ActionCable - HermineRequestChannel - DISCONNECTED')
        },
        received: (data: any) => {
          console.debug('[+] ActionCable - HermineRequestChannel - received data: ', data)
          if (data?.response) {
            setValue?.('disputeValueCalculationDescription', data.response, { shouldDirty: true })
            setHermineRequestFailed(false)
          } else if (data?.error_log) {
            setValue?.('disputeValueCalculationDescription', t('ai:disputeValueModal.error'))
            Sentry.captureMessage('HermineRequest failed for dispute value calculation.', {
              extra: data,
              level: 'warning'
            })
            setHermineRequestFailed(true)
            setSnackbar({
              open: true,
              variant: 'error',
              text: t('common:errorLong')
            })
          }
        }
      })
    }
    return () => {
      console.debug('[-] Unmounting HermineRequestChannel')
      const token = getToken()
      const client = getClient()
      const params = {
        channel: 'HermineRequestChannel',
        user_id: `${user?.id}`,
        hermine_request_id: hermineRequestId,
        token,
        client
      }
      const sub = consumer.subscriptions.subscriptions?.find(
        (s: any) => s.identifier === JSON.stringify(params)
      )

      sub?.unsubscribe()
    }
  }, [hermineRequestId, setSnackbar, setValue, t, user?.id])

  const [createHermineRequest, { called, error }] = useMutation(CREATE_HERMINE_REQUEST_MUTATION)
  const { m2Case } = useCase()
  const calculateDisputeValue = () => {
    createHermineRequest({
      variables: {
        attributes: {
          buildingBlockSlug: 'm2_calc_dispute_value',
          resourceId: m2Case.id,
          resourceType: 'M2Case',
          input: JSON.stringify({
            text: context
          })
        }
      },
      onCompleted: (data: any) => {
        if (data?.createHermineRequest?.id) setHermineRequestId(data?.createHermineRequest?.id)
        else {
          Sentry.captureException(new Error('Hermine not available'), { extra: data })
          setSnackbar({
            open: true,
            variant: 'error',
            text: t('common:errorLong')
          })
        }
      },
      onError: (errorData: any) => {
        if (errorData.message === 'global.error.hermineNotAvailable') {
          setSnackbar({
            open: true,
            variant: 'error',
            text: t('ai:hermineNotAvailable')
          })
        } else {
          Sentry.captureException(new Error('Hermine not available'), { extra: errorData })
          setSnackbar({
            open: true,
            variant: 'error',
            text: t('common:errorLong')
          })
        }
      }
    })
  }

  const renderModal = () => (
    <Dialog open={open}>
      <DialogTitle>{t('disputeValueModal.title')}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography sx={{ pb: 2 }} variant="body2">
            {t('disputeValueModal.content')}
          </Typography>
          <Input
            rows={5}
            multiline
            type="textfield"
            value={context}
            onChange={(event: any) =>
              setValue?.(name || '', event.target.value, { shouldDirty: true })
            }
            label={t('disputeValueModal.inputLabel')}
            fullWidth
          />
          <Button color="primary" onClick={calculateDisputeValue}>
            {t('disputeValueModal.submit')}
          </Button>
          {called && !error && !disputeValueCalculationDescription && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />
            </Box>
          )}
          {disputeValueCalculationDescription && (
            <Box gap={2}>
              <Typography variant="body2">{t('disputeValueModal.description')}</Typography>
              <Box sx={hermineRequestFailed ? { color: 'red' } : {}}>
                <Markdown>{disputeValueCalculationDescription}</Markdown>
              </Box>
            </Box>
          )}
          <Input
            multiline
            type="money"
            value={watch?.('disputeValue')}
            onChange={(event: any) =>
              setValue?.('disputeValue', event.target.value, { shouldDirty: true })
            }
            label={t('disputeValueModal.disputeValue')}
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => toggleOpen(false)}>
          {t('disputeValueModal.save')}
        </Button>
        <Button color="secondary" onClick={() => toggleOpen(false)}>
          {t('disputeValueModal.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
  return (
    <>
      <IconButton color="primary" onClick={() => toggleOpen(true)}>
        <Info />
      </IconButton>
      {renderModal()}
    </>
  )
}

export default DisputeValueModal
